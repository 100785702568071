<template>
  <div class="preorder-list">
    <div class="filters">
      <div class="date-filter">
        <v-text-field
          @click="modal = true"
          :label="$t('form.filter_by_date')"
          v-model="dateFormatted"
          prepend-icon="mdi-calendar"
          readonly/>

        <v-btn color="primary" text small fab @click.native="resetOrders">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-flex xs12>
      <orders-table
        height="calc(90vh - 107px)"
        type="closed"
        :orders="orders"
        :date="dateFormatted"
        :loading="loading"
        :pagination="pagination"
        @changePage="filterByDate"
      />
    </v-flex>

    <v-dialog
      persistent
      v-model="modal"
      width="290px">
      <v-date-picker
        :value="date"
        @input="val => selectedDate = val"
        :locale="$i18n.locale"
        :max="dateNow"
        scrollable actions>
        <template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">{{ $t('cancel') }}</v-btn>
            <v-btn text color="primary" @click="($event) => { date = selectedDate; modal = false; }">
              {{ $t('ok') }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import OrdersTable from './OrdersTable'
import settings from '@/config/settings'

export default {
  components: {
    OrdersTable
  },
  data: () => ({
    loading: false,
    dateNow: moment().format('YYYY-MM-DD'),
    selectedDate: null,
    date: null,
    dateFormatted: null,
    orders: [],
    modal: false,
    interval: null,
    pagination: {
      page: 1,
      total: 0,
      per_page: 50
    }
  }),
  computed: {
    ...mapGetters([
      'getClosedOrders',
      'getClosedOrdersDay'
    ])
  },
  methods: {
    ...mapActions([
      'loadOrdersWithDate'
    ]),
    formatDate (date) {
      if (!date) {
        date = this.dateNow
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    filterByDate (page = 1) {
      this.pagination.page = page
      this.loading = true
      this.loadOrdersWithDate({ page, date: this.dateFormatted, pre: false }).then(response => {
        this.orders = response.orders
        this.pagination.total = response.pagination.total
        this.pagination.per_page = response.pagination.per_page
      }).finally(() => {
        this.loading = false
      })
    },
    resetOrders () {
      this.date = this.dateNow
      this.filterByDate()
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(val)
    },
    dateFormatted (val) {
      this.filterByDate()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'closed') {
      clearInterval(this.interval)
    }
    next()
  },
  created () {
    this.date = this.dateNow

    if (!this.interval) {
      this.interval = setInterval(() => {
        this.filterByDate(this.pagination.page)
      }, settings.ORDERS_UPDATE_TIME * 1000)
    }
  }
}
</script>
<style scoped lang="scss">
.filters {
  padding: 1rem;
  height: 10vh;
  .date-filter {
    display: flex;
    .v-text-field {
      flex: 0 0 30%;
    }
  }
}
</style>
