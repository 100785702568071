<template>
  <v-dialog
    id="orderDetailsDialog"
    :value="visible"
    @input="$emit('close')"
    fullscreen
    return-value="true"
    persistent
    transition="dialog-bottom-transition"
    no-click-animation
    :overlay=false
  >
    <v-card>
      <v-toolbar dark class="light-green darken-1">
        <v-btn icon @click.native="closeModal" dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('order_modal.title') }} № {{ order.id }}</v-toolbar-title>
        <v-btn v-show="!order.completed" class="ml-auto" color="red" dark :loading="order.pending"
               @click.native="cancelOrder(order)">
          <v-icon dark left>mdi-delete</v-icon>
          {{ $t('order_modal.cancel') }}
        </v-btn>
      </v-toolbar>
      <v-card-text class="fill-height order-card">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs6>
              <v-card class="elevation-6 d-flex flex-column justify-space-between" height="100%">
                <v-card-text class="px-2">
                  <v-row align="center" justify="space-between" class="mt-0">
                    <v-col md="5">
                      <span class="grey--text">
                          <v-icon>mdi-bell-outline</v-icon>
                        </span>
                      {{ $t('status') }}
                    </v-col>
                    <v-col md="7" class="d-flex align-center">
                      <div class="order-status-icon">
                        <component class="px-1" :is="statusIcon.component" :style="{fill: statusIcon.color}"/>
                      </div>
                      <span class="title">
                        {{ order.statusName }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row justify="space-between" align="center" class="mt-0">
                    <v-col md="5">
                      <span class="grey--text">
                        <v-icon>mdi-calendar-range</v-icon>
                      </span>
                      {{ order.date }}
                    </v-col>
                    <v-col md="7" class="d-flex align-center">
                      <span v-if="!order.soon"><v-icon>mdi-clock-outline</v-icon></span>
                      <span :class="{'display-1 red--text': !order.soon, 'headline': order.soon}">
                        {{ order.orderTime }}
                        <span v-if="order.soon">
                          ({{ order.time }})
                        </span>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row justify="space-between" align="center" class="mt-0">
                    <v-col md="5">
                      <span class="grey--text">
                        <v-icon>mdi-cash-register</v-icon> {{ $t('amount') }}:
                      </span>
                      <span class="mx-1">{{ getAmountFormat(order.amount) }}</span>
                    </v-col>
                    <v-col md="7">
                      <span class="subheading">
                        <template v-if="order.paid">
                          <v-chip color="green" text-color="white"><v-icon left>mdi-check-circle</v-icon> {{
                              $t('paid')
                            }} </v-chip>
                        </template>
                        <template v-else>
                          <v-chip color="red" text-color="white"> {{ $t('not_paid') }}</v-chip>
                        </template>
                      </span>
                    </v-col>
                  </v-row>

                  <div class="my-3 d-flex justify-space-between align-center">
                    <div class="d-flex me-2" style="width: 85%; overflow-x: auto">
                      <div
                        style="min-width: 150px"
                        class="me-2"
                        v-for="(payment, index) in order.payment_types"
                        :key="'payment-' + index"
                      >
                        <v-card flat width="160"
                                :color="payment.status_payment ? 'light-green lighten-2' : 'blue-grey lighten-5'">
                          <v-card-text class="black--text d-flex flex-column" style="padding: 5px 10px;line-height: 1.4;">
                            <div>{{ payment.name }}</div>
                            <div class="d-flex justify-space-between" style="font-weight: 500;">
                              <div
                                :class="[payment.status_payment ? 'black--text' : 'red--text text--darken-1']"
                                style="font-size: 11px;"
                              >
                                {{ $t(payment.status_payment ? 'paid' : 'not_paid') }}
                              </div>
                              <div>{{ getAmountFormat(payment.amount) }}</div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                    <div>
                      <v-btn height="55" elevation="0" color="light-blue lighten-4" @click="showPaymentModal('local')"
                             v-if="showChangePaymentBtn">
                        <EditIcon />
                      </v-btn>
                    </div>
                  </div>
                  <v-row>
                    <template>
                      <v-col cols="12" v-if="order.agregator && order.agregator.externalNumber !== null" class="py-0">
                        {{ $t('order_modal.order_card.aggregator') }}
                        <v-chip class="mx-2" color="pink lighten-1" label text-color="white"
                                style="border-radius: 4px !important;">
                          <v-icon left>mdi-label</v-icon>
                          {{ order.agregator.externalNumber }}
                        </v-chip>
                      </v-col>
                    </template>
                  </v-row>

                  <v-row v-if="order.additional_info !== ''">
                    <v-col cols="12">
                      <span class="grey--text">{{ $t('order_modal.order_card.comment_for_cashier') }}</span>
                      <v-alert :value="true" width="100%" color="error" class="ma-0">
                        {{ order.additional_info }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
                <div>
                  <v-card-actions>
                    <v-row justify="space-between" class="pb-2">
                      <v-col md="6" sm="12">
                        <v-btn @click="openProducts" width="100%">
                          <v-icon dense class="px-1">mdi-basket</v-icon>
                          {{ $t('order_modal.btn.products_list') }}
                        </v-btn>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-btn @click="openHistory" width="100%">
                          <v-icon dense class="px-1">mdi-history</v-icon>
                          {{ $t('order_modal.btn.order_history') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <template v-if="order.isSendIIKO">
                    <v-btn style="color: white;" tile :loading="sendIIKOLoading"
                           :disabled="!statusForSendToIiko && !isWorkshop" @click="sendIIKOBtnHandler"
                           block large
                           :color="!statusForSendToIiko && !isWorkshop ? 'grey lighten-1' : retrySendOrder ? 'orange lighten-1' : 'red darken-1'">
                      <img class="pe-3" src="/static/pay_and_send_iiko.svg" alt="">
                      {{ retrySendOrder ? $t('order_modal.btn.resend_to_IIKO') : $t('order_modal.btn.send_to_iiko') }}
                    </v-btn>
                    <v-alert v-if="!statusForSendToIiko && !isWorkshop" color="red" text type="info"
                             class="text-center">
                      {{ $t('order_modal.msg.order_status_not_valid_for_send_to_iiko') }}
                    </v-alert>
                  </template>
                </div>
              </v-card>
            </v-flex>

            <v-flex xs6>
              <v-card class="elevation-6 lime lighten-2 mb-2">
                <v-card-text class="px-3 black--text">
                  <div class="text-h5 d-flex align-center">
                    <div class="d-flex align-center">
                      <v-icon class="black--text mr-1">mdi-emoticon-outline</v-icon>
                      <span class="mr-3">{{ $t('client') }}:</span>
                    </div>
                    {{ order.client_name }}
                  </div>
                  <div class="display-1">{{ order.phone_client }}</div>
                </v-card-text>
              </v-card>

              <v-card class="elevation-6 " v-if="order.type === 'delivery'">
                <v-card-text class="px-3">
                  <h2 class="black--text">
                    <v-icon class="px-1 black--text">mdi-car</v-icon>
                    {{ $t('delivery') }}
                  </h2>
                  <div class="black--text py-1"><span class="grey--text"><v-icon>mdi-map-marker</v-icon> </span>
                    {{ order.street }}, {{ order.house }}
                  </div>
                  <div class="black--text py-1">
                    <div class="d-flex align-center">
                      <span class="d-flex grey--text mr-1">
                      <span v-if="order.courier_service_id && order.courier.icon">
                        <v-img class="mr-1" max-height="24" max-width="24" :src="order.courier.icon"></v-img>
                      </span>
                      <span v-else>
                        <v-icon class="mr-1">mdi-face</v-icon>
                      </span>

                      {{ $t('courier') }}:
                    </span>
                      <template v-if="order.courierID !== 0 || order.courier_service_id">{{
                          order.courier.name
                        }}
                      </template>

                      <template v-if="!order.completed && order.isSelectCourier">
                        <template v-if="order.courierID === 0 && !order.courier_service_id">
                          <v-btn color="error" outlined @click="selectCourierList(order)">
                            <v-icon class="px-1">mdi-gesture-tap</v-icon>
                            {{ $t('order_modal.btn.assign_courier') }}
                          </v-btn>
                        </template>
                        <template v-else-if="!order.courier_service_id">
                          <v-btn class="ma-1" outlined color="indigo" @click="selectCourierList(order)">
                            <v-icon class="px-1" left>mdi-pencil</v-icon>
                            {{ $t('change') }}
                          </v-btn>
                        </template>
                      </template>
                    </div>

                    <div class="mt-1" v-if="order.courier_service_id && !order.skip_confirmation">
                      <v-btn color="amber lighten-2" class="mx-1" elevation="2" @click="openCodesModal()">
                        {{ $t('confirmation_code') }}
                      </v-btn>
                    </div>
                  </div>

                  <div class="black--text py-1" v-if="order.onlinePayment.isActive === false">
                    <span class="grey--text">
                      <v-icon>mdi-cash-register</v-icon> {{ $t('order_modal.delivery.from_client') }}:
                    </span>
                    {{ getAmountFormat(cashByOrderPayments) }}
                    <template v-if="cashByOrderPayments > cashAmount">
                      <v-chip color="red" text-color="white" small>
                        <v-icon>mdi-alert-circle-outline</v-icon> &nbsp;&nbsp; {{
                          $t('order_modal.delivery.msg.change', { change: getAmountFormat(cashByOrderPayments - cashAmount) })
                        }}
                      </v-chip>
                    </template>
                  </div>
                  <div class="black--text py-1" v-if="order.isSelectCourier">
                    <span class="grey--text"><v-icon>mdi-message-reply-text</v-icon> {{
                        $t('order_modal.delivery.comment_for_courier')
                      }}:</span>
                    <template v-if="order.deliveryComment !== ''">
                      <div class="px-2"> {{ order.deliveryComment }}</div>
                    </template>
                  </div>
                </v-card-text>
              </v-card>

              <v-alert
                :value="true"
                color="info"
                icon="mdi-run"
                v-else
                outlined>
                {{ $t('order_modal.msg.type_takeaway') }}
              </v-alert>
            </v-flex>
          </v-layout>

          <br>
          <v-divider></v-divider>
          <br>
          <v-layout>

            <v-flex xs12>
              <v-container grid-list-md text-center>
                <v-layout row wrap>

                  <v-flex xs12 v-if="order.status === 12 || order.status === 13">
                    <v-alert :value="true" type="success" v-if="order.status === 12">
                      {{ $t('order_modal.statuses.closed') }}
                    </v-alert>
                    <v-alert :value="true" type="error" v-if="order.status === 13">
                      {{ $t('order_modal.statuses.cancelled') }}
                    </v-alert>
                  </v-flex>

                  <v-flex xs12 v-if="!order.isOrderAccepted && order.status !== 12  && order.status !== 13">
                    <v-alert :value="true" outlined color="info" icon="mdi-information">
                      {{ $t('order_modal.msg.order_not_accepted') }}
                    </v-alert>

                    <v-btn
                      v-if="!order.isOrderAccepted"
                      block
                      color="light-green darken-1"
                      dark
                      large
                      :loading="order.pending"
                      @click="acceptOrder(order)"
                    >
                      <v-icon left>mdi-check-circle</v-icon>
                      {{ $t('order_modal.btn.accept_order') }}
                    </v-btn>

                  </v-flex>

                  <v-flex xs12 v-if="order.isOrderAccepted || order.status === 12 || order.status === 13">
                    <v-btn block color="cyan darken-1" dark @click="openCheck">
                      <v-icon left>mdi-printer</v-icon>
                      {{ $t('order_modal.btn.print') }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs6>
                    <template v-if="!order.completed  && order.status !== 12  && order.status !== 13">
                      <v-btn
                        v-if="order.isOrderReadyToCook"
                        block
                        color="light-green darken-1"
                        dark
                        large
                        :loading="order.pending"
                        @click="cookingOrder(order)">
                        <v-icon left>mdi-silverware</v-icon>
                        {{ $t('order_modal.btn.start_cooking') }}
                      </v-btn>
                      <v-btn
                        v-if="order.isOrderCooking"
                        block
                        color="light-green darken-1"
                        dark
                        large
                        :loading="order.pending"
                        @click="cookedOrder(order)">
                        <v-icon left>mdi-marker-check</v-icon>
                        {{ $t('order_modal.btn.order_ready') }}
                      </v-btn>
                      <v-btn
                        v-if="order.isOrderReady"
                        block
                        color="light-green darken-1"
                        dark
                        large
                        :loading="order.pending"
                        @click="orderComplete(order)">
                        <v-icon left>mdi-silverware-fork-knife</v-icon>
                        {{ $t('order_modal.btn.complete_order') }}
                      </v-btn>
                    </template>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn block large color="light-blue darken-1" dark
                           @click.native="closeModal">{{ $t('back') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-layout row justify-center>
      <v-dialog v-model="errorOrderAlert">
        <v-card full-width="true">
          <v-card-title class="headline">{{ $t('order_modal.error_box.title') }}</v-card-title>
          <v-card-text>

            <v-alert class="text-center" :value="true" type="error">
              {{ $t('order_modal.error_box.alert') }}
            </v-alert>

          </v-card-text>
          <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn color="info"
                   @click.native="errorOrderAlert = false; $store.state.orderDialog.show = false; delete_order(order)">
              {{ $t('order_modal.error_box.btn') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <div class="text-xs-center">
      <v-dialog
        v-model="dialogLoading"
        persistent
        width="300">
        <v-card
          color="primary"
          dark>
          <v-card-text>
            {{ dialogLoadingText }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog
      v-model="dialogLoadError.show"
      width="500">
      <v-card>
        <v-card-title
          class="headline red lighten-2"
          primary-title>
          {{ dialogLoadError.title }}
        </v-card-title>

        <v-card-text>
          {{ dialogLoadError.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogLoadError.show = false">
            {{ $t('well') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showChange"
      width="500"
    >
      <v-card class="pa-7">
        <div class="d-flex flex-column align-center">
          <div style="font-size: 24px; color: #546E7A">{{ $t('money.change.self') }}</div>
          <div class="my-4" style="font-size: 50px; color: #01579B; font-weight: 600;">
            {{ $n($store.state.orderDialog.changeModal.change, 'currency') }}
          </div>
          <div style="width: 100%;">
            <v-btn rounded height="77" style="color: white; font-size: 24px" width="100%" @click="closePaymentModal"
                   depressed color="#546E7A">OK
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <PaymentTypeModal
      :visible="payment.show"
      :order="order"
      :mode="payment.mode"
      :list="payment.list"
      @change="changePayment"
      @close="payment.show = false"
      @send-to-iiko="changeAndSentIiko"
    />

    <error-modal
      :visible="dialogLoadError.visible"
      :title="dialogLoadError.title"
      :message="dialogLoadError.message"
    ></error-modal>

    <confirm-order-modal
      :code="codesModal.code"
      :loading="codesModal.loading"
      :visible="codesModal.visible"
      :message="codesModal.message"
      @close="codesModal.visible = false"
    ></confirm-order-modal>

    <couriers-modal
      :couriers="courierList.list"
      :visible="courierList.show"
      :order="order"
      @hide="() => {this.courierList.show = false}">
    </couriers-modal>

    <iiko-order-modal
      :visible="iikoNumbers.visible"
      :loading="iikoNumbers.loading"
      :list="iikoNumbers.list"
      @close="iikoNumbers.visible = false"
      @send="sendIIKO()"
    />

    <order-products-modal
      :visible="products.visible"
      :list="products.list"
      :order="order"
      @close="products.visible = false"/>

    <order-history-modal
      :visible="history.visible"
      :list="history.list"
      :order="order"
      @close="history.visible = false"/>
  </v-dialog>
</template>

<script>

import qs from 'qs'
import axios from 'axios'
import config from '../config/settings'
import OrderProductsModal from './Modals/OrderProductsModal'
import OrderHistoryModal from './Modals/OrderHistoryModal'
import CouriersModal from './Modals/CouriersModal'
import ConfirmOrderModal from './Modals/ConfirmOrderModal'
import moment from 'moment'
import PaymentTypeModal from './Modals/PaymentTypeModal.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import iikoOrderModal from './Modals/iikoOrderModal.vue'
import ErrorModal from './Modals/ErrorModal.vue'

const VALID_STATUSES_FOR_SEND_TO_IIKO = [
  5, 6, 7, 8, 12
]

export default {
  props: {
    visible: Boolean,
    order: Object
  },
  components: {
    ErrorModal,
    iikoOrderModal,
    OrderProductsModal,
    OrderHistoryModal,
    PaymentTypeModal,
    CouriersModal,
    ConfirmOrderModal
  },
  data: () => ({
    sendIIKOLoading: false,
    products: {
      visible: false,
      list: []
    },
    history: {
      visible: false,
      list: []
    },
    payment: {
      show: false,
      mode: null,
      list: [],
      defaultList: []
    },
    iiko_payments: {
      loading: true,
      types: []
    },
    iikoNumbers: {
      visible: false,
      loading: false,
      list: []
    },
    sheetCourier: false,
    courierList: {
      show: false,
      list: []
    },
    codesModal: {
      loading: false,
      visible: false,
      message: {
        text: '',
        type: null
      },
      code: null
    },
    errorOrderAlert: false,
    dialogLoadError: {
      visible: false,
      title: '',
      message: ''
    },
    dialogLoading: false,
    dialogLoadingText: ''
  }),
  watch: {
    visible (val) {
      if (val) {
        this.$props.order.iikoOrderReason = ''
        this.getTypePayment()
      }
    }
  },
  methods: {
    ...mapActions([
      'loadOrderProducts',
      'loadOrderHistory',
      'showSnackbar'
    ]),
    ...mapMutations([
      'setLoading'
    ]),

    closeModal () {
      this.$store.state.orderDialog.show = false
      this.iikoNumbers.list = []
    },

    getAmountFormat (amount) {
      return Number(amount).toFixed(2).replace('.', ',')
    },

    openProducts () {
      this.setLoading({ value: true })
      this.products.list = null

      this.loadOrderProducts(this.order.id).then(response => {
        this.products.list = response
        this.products.visible = true
      }).finally(() => {
        this.setLoading({ value: false })
      })
    },

    openHistory () {
      this.setLoading({ value: true })
      this.history.list = null

      this.loadOrderHistory(this.order.id).then(response => {
        this.history.list = response
        this.history.visible = true
      }).finally(() => {
        this.setLoading({ value: false })
      })
    },

    async changePayment (payments) {
      this.dialogLoading = true

      await this.order.changePayment(payments).then(response => {
        this.getTypePayment()
        this.payment.show = false
      }).catch(() => {
        this.payment.list = this.payment.defaultList
      }).finally(() => {
        this.dialogLoading = false
      })
    },

    changeAndSentIiko (payments, change) {
      this.changePayment(payments).then(() => {
        this.checkIIKONumbersBeforeSendIIKO(this.$t('initial_sending_iiko'))
        this.$store.state.orderDialog.changeModal.change = change
      })
    },

    selectCourierList (order) {
      this.dialogLoading = true
      order.courierList().then(response => {
        if (response.length === 0) {
          this.dialogLoadError.visible = true
          this.dialogLoadError.title = this.$t('order_modal.courier_modal.err.title')
          this.dialogLoadError.message = this.$t('order_modal.courier_modal.err.msg')
        } else {
          response.push({ id: 0, name: this.$t('order_modal.courier_modal.without_courier') })
          this.courierList.show = true
          this.courierList.list = response
        }
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    showPaymentModal (mode) {
      this.dialogLoading = true
      this.order.paymentList(this.order.type).then(response => {
        this.payment.list = response.data
        this.payment.defaultList = response.data
        this.payment.mode = mode
        this.payment.show = true
      }).catch(error => {
        let message = this.$t('errors.server_error')
        if (error.response) {
          if (error.response.status === 400) {
            message = error.response.data.message
          }
        }
        this.$store.dispatch('showSnackbar', message)
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    closePaymentModal () {
      this.payment = {
        show: false,
        mode: null,
        list: []
      }

      this.$store.state.orderDialog.changeModal = {
        change: 0,
        show: false
      }
    },
    delete_order (order) {
      this.$store.state.order_lastupdate = 0
      this.$store.dispatch('loadOrders')
    },
    cancelOrder (order) {
      order.pending = true

      order.cancel().then(response => {
        order.accepted = response.accepted
        order.accepted_at = ''
        order.status = response.status
        order.completed = response.completed
      }).finally(() => {
        order.pending = false
      })
    },
    acceptOrder (order) {
      order.pending = true

      order.changeStatus('accept').then(response => {
        order.accepted = true
        order.accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
        order.status = config.ORDER_STATUS_TIME_TO_COOK
        order.actual_utc_completion_time = null
        order.print({
          success: this.$t('text.order_confirmed')
        })
        this.$store.commit('updateOrder', order)
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$store.dispatch('showSnackbar', error.response.data.message)
        }
      }).finally(() => {
        order.pending = false
      })
    },
    cookingOrder (order) {
      this.$store.dispatch('showConfirmDialog', {
        header: this.$t('order_modal.question.order_cooking'),
        text: '',
        callback: function () {
          order.pending = true

          order.changeStatus('cooking').then(response => {
            order.status = config.ORDER_STATUS_COOKING
            this.$store.commit('updateOrder', order)
          }).catch(error => {
            if (error.response && error.response.status === 400) {
              this.$store.dispatch('showSnackbar', error.response.data.message)
            }
          }).finally(() => {
            order.pending = false
          })
        }
      })
    },
    cookedOrder (order) {
      this.$store.dispatch('showConfirmDialog', {
        header: this.$t('order_modal.question.order_ready'),
        text: '',
        callback: function () {
          order.pending = true

          order.changeStatus('cooked').then(response => {
            if (order.type === 'delivery') {
              order.status = config.ORDER_STATUS_WAITING_DELIVERY
            } else {
              order.status = config.ORDER_STATUS_READY
            }
            this.$store.commit('updateOrder', order)
          }).catch(error => {
            if (error.response && error.response.status === 400) {
              this.$store.dispatch('showSnackbar', error.response.data.message)
            }
          }).finally(() => {
            order.pending = false
          })
        }
      })
    },
    orderComplete (order) {
      order.pending = true

      order.complete().then(response => {
        order.status = response.status
        order.completed = response.completed
        order.completed_at = response.completed_at
      }).finally(() => {
        order.pending = false
      })
    },
    sendIIKOBtnHandler () {
      if (this.order.paid) {
        this.checkIIKONumbersBeforeSendIIKO()
      } else {
        this.showPaymentModal('iiko')
      }
    },
    async checkIIKONumbersBeforeSendIIKO () {
      this.setLoading({ value: true, text: this.$t('order_modal.loader.send_to_iiko') })

      await this.getOrderIIKONumbers().then(response => {
        if (response.data.length !== 0) {
          this.showIIKONumbersModal(response.data)
        } else {
          this.sendIIKO(this.$t('initial_sending_iiko'))
        }
      }).finally(() => {
        this.setLoading({ value: false })
      })
    },
    async sendIIKO (reason = null) {
      this.setLoading({ value: true, text: this.$t('order_modal.loader.send_to_iiko') })

      this.iikoNumbers.visible = false
      this.$store.state.orderDialog.order.iikoNumber = null
      const user = (localStorage.getItem('user')) ? JSON.safeParse(localStorage.getItem('user')) : false

      await this.$store.state.worker.postMessage({
        type: 'kassaSendIIKO',
        lang: this.$i18n.locale,
        token: user.token,
        url: config.API_HOST + '/v2/iiko/sendFront/' + this.order.id,
        params: {
          user_id: user.id,
          token: user.token
        },
        headers: {
          'X-Request-ID': crypto.randomUUID()
        },
        reason: reason ?? this.order.iikoOrderReason,
        apiUrl: config.API_HOST
      })

      this.showSnackbar('Запрос отправлен в IIKO')
    },
    openCheck () {
      let printDriver = config.PRINT_INVOICE_DRIVER ?? null

      if (printDriver) {
        printDriver = printDriver.toLowerCase()
      }

      switch (printDriver) {
        case 'browser':
          this.receiptBrowser()
          break
        case 'iiko':
        default:
          this.receiptIIKO()
      }
    },

    receiptIIKO () {
      this.order.print({
        success: this.$t('print_successfully')
      })
    },

    receiptBrowser () {
      this.dialogLoadingText = this.$t('order_modal.loader.send_to_print')
      this.dialogLoading = true
      const user = (localStorage.getItem('user')) ? JSON.safeParse(localStorage.getItem('user')) : false
      axios({
        method: 'POST',
        url: config.API_HOST + '/kassa/print/checkOrder/' + this.order.id,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          user_id: user.id,
          token: user.token
        })
      }).then(response => {
        if (response.data === 'error') {
          this.errorOrderAlert = true
        } else {
          const w = window.open('about:blank', this.$t('report'), 'left=50,top=50,width=800px,height=640,toolbar=0,scrollbars=1,status=0')
          w.document.open()
          w.document.write(response.data)
          w.document.close()
        }
      }).catch(() => {
        this.dialogLoadError.visible = true
        this.dialogLoadError.title = this.$t('error')
        this.dialogLoadError.message = this.$t('errors.one_more_devices')
      }).finally(() => {
        this.dialogLoading = false
      })
    },

    getOrderIIKONumbers () {
      return new Promise((resolve, reject) => {
        this.order.iikoOrders().then(response => {
          resolve(response)
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.dialogLoadError.visible = true
            this.dialogLoadError.title = this.$t('error')
            this.dialogLoadError.message = error.response.data.message
          }
          this.iikoNumbers.list = []
          reject(error)
        })
      })
    },
    showIIKONumbersModal (data) {
      this.iikoNumbers.list = data
      this.iikoNumbers.visible = true
    },
    openCodesModal () {
      this.codesModal.message = { text: null, type: null }
      this.codesModal.code = null

      this.codesModal.loading = true
      this.$store.dispatch('getConfirmationCode', {
        orderId: this.order.id
      }).then(response => {
        if (response.data) {
          this.codesModal.message = { text: '', type: null }
          this.codesModal.code = response.data.code
        } else {
          this.codesModal.message.text = this.$t('confirm_order.there_is_no_confirmation_code')
          this.codesModal.message.type = 'error'
        }
      }).catch(error => {
        this.codesModal.message.text = this.$t('error')
        this.codesModal.message.type = 'error'
        this.codesModal.length = 0

        if (error.response.data) {
          this.$store.dispatch('showSnackbar', error.response.data.message)
        }
      }).finally(() => {
        this.codesModal.loading = false
        this.codesModal.visible = true
      })
    },
    getTypePayment () {
      this.iiko_payments.loading = true
      this.iiko_payments.types = []

      /* axios.post(config.API_HOST + '/kassa/paymentType/get-payment-type-by-id', { order_id: this.order.id })
        .then(response => {
          this.iiko_payments.types = response.data
        }).finally(() => {
          this.iiko_payments.loading = false
        }) */
    }
  },
  created () {
    this.payment.cash = this.order.kassa_cash
    this.payment.card = this.order.kassa_card
    this.dialogLoadingText = this.$t('order_modal.loader.load_data')
    this.dialogLoadError.title = this.$t('error')
    this.dialogLoadError.message = this.$t('errors.one_more_devices')
  },
  computed: {
    ...mapGetters(['eventBus']),
    cashByOrderPayments () {
      let sum = 0.0
      this.order.payment_types.filter(el => el.is_cash).forEach(el => {
        sum += el.amount_change
      })

      return sum
    },
    showChange () {
      const changeModal = this.$store.state.orderDialog.changeModal
      return changeModal.show && changeModal.change
    },
    cashAmount () {
      return this.order.payment_types.reduce((out, item) => {
        if (item.is_cash) {
          out += item.amount
        }
        return out
      }, 0)
    },
    retrySendOrder () {
      return this.iikoNumbers.list.length !== 0
    },
    statusIcon () {
      return this.order.statusIcon ?? {
        component: null,
        color: '#fff'
      }
    },
    statusForSendToIiko () {
      return VALID_STATUSES_FOR_SEND_TO_IIKO.includes(this.order.status)
    },
    isWorkshop () { // является ли точка цехом
      return this.$store.state.user.type === 2
    },
    showChangePaymentBtn () {
      const validByStatus = this.order.status !== config.ORDER_STATUS_CANCELED
      const validByPayment = (this.order.payment === 0 || this.order.payment === 1 || this.order.payment === 4)
      const validByCompletedAt = (this.order.actual_utc_completion_time) ? moment.utc(this.order.actual_utc_completion_time).add(1, 'hour') > moment() : true
      return validByStatus && validByPayment && validByCompletedAt && !this.order.paid
    }
  },
  mounted () {
    this.eventBus.$on('openOrderPaymentTypes', async ({ order, mode }) => {
      if (!this.order) {
        await this.eventBus.$emit('openOrder', order)
      }
      this.showPaymentModal(mode)
    })

    this.eventBus.$on('closeOrder', (order) => {
      if (this.order.id === order.id) {
        this.closeModal()
      }
    })
  }
}
</script>
<style lang="scss">
.order-card {
  height: calc(100vh - 64px);
  overflow: auto;
}

.order-status-icon {
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;

  & > svg {
    width: 100%;
  }
}
</style>
