<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-flex xs12>
              <orders-table
                height="calc(100vh - 48px)"
                type="orders"
                :orders="orders"
                :with-filters="true"
                :pagination="pagination"
                @changePage="changePage"
              />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrdersTable from './OrdersTable'

export default {
  components: {
    OrdersTable
  },
  computed: {
    ...mapState([
      'pagination'
    ]),
    ...mapGetters({
      orders: 'getActiveOrders'
    })
  },
  methods: {
    ...mapActions([
      'loadOrders'
    ]),
    ...mapMutations([
      'setBadges',
      'updatePaginationPage'
    ]),
    changePage (page) {
      this.updatePaginationPage(page)
      this.loadOrders()
    }
  },
  mounted () {
    this.setBadges({ type: 'active', value: this.getActiveOrders?.length ?? 0 })
    this.loadOrders()
  }
}
</script>
