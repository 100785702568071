const API_HOST = '/v2/auth/'

export function byLogin (context, params) {
  return this._vm.axios.post(API_HOST + 'login', params)
}

export function byToken (context, token) {
  return this._vm.axios.post(API_HOST + 'token', { token })
}

export function logout (context) {
  return this._vm.axios.post(API_HOST + 'logout')
}
