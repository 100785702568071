<template>
  <div>
    <order-filters :badges="badges" v-if="withFilters" :value="filters" @input="changeFilters"/>

    <div :style="{ height: currentHeight }" v-if="sortedOrders.length !== 0">
      <order-table-header
        :headers="headers"
        :sort="sort.value"
        :sort-type="sort.type"
        @sort="changeSort"
      />

      <div class="order-table-rows" ref="tableBody">
        <order-table-row
          v-for="(order, index) in sortedOrders"
          :key="'order-row-' + index"
          :order="order"
          @click="chooseOrder"
        />
      </div>

      <order-table-footer
        :per-page="pagination.per_page"
        :current-page="pagination.page"
        :total="pagination.total"
        @change="changePage"
      />
    </div>

    <div class="order-table-no-data" :style="{height: currentHeight}" v-else>
      <div class="order-table-no-data-label">
        {{ $t(`table.msg.${this.type}_empty`, {date: this.date }) }}
      </div>
      <div class="order-table-no-data-icon">
        <no-data-xmas-icon v-if="[0, 11].includes(new Date().getMonth())"/>
        <no-data-icon v-else/>
      </div>
      <div v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>

    <other-seller-modal
      :visible="otherSeller.visible"
      :order="otherSeller.order"
      @close="otherSeller.visible = false"
    />

    <orders-details-dialog
      v-if="$store && $store.state.orderDialog"
      :visible="$store.state.orderDialog.show"
      :order="$store.state.orderDialog.order"
      @close="$store.state.orderDialog.show = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrdersDetailsDialog from './OrdersDetailsDialog'
import OrderFilters from './Order/Table/OrderFilters'
import OrderTableHeader from './Order/Table/OrderTableHeader.vue'
import OrderTableRow from './Order/Table/OrderTableRow.vue'
import OrderTableFooter from './Order/Table/OrderTableFooter.vue'
import OtherSellerModal from './Modals/OtherSellerModal.vue'

export default {
  name: 'OrdersTable',
  components: {
    OtherSellerModal,
    OrderTableFooter,
    OrderTableRow,
    OrderTableHeader,
    OrdersDetailsDialog,
    OrderFilters
  },
  props: {
    loading: {
      type: Boolean || null,
      default: null
    },
    withFilters: {
      type: Boolean,
      default: false
    },
    height: String,
    type: String,
    orders: Array,
    date: {
      type: String,
      default: null
    },
    pagination: Object
  },
  data: () => ({
    badges: {
      types: {
        delivery: 0,
        pickup: 0
      },
      statuses: {}
    },
    sort: {
      value: null,
      type: 'asc'
    },
    drag: false,
    pos: { top: 0, left: 0, x: 0, y: 0 },
    otherSeller: {
      visible: false,
      order: null
    }
  }),
  computed: {
    ...mapGetters([
      'isOrdersLoading',
      'getErrors',
      'statusesForFilters',
      'eventBus'
    ]),
    ...mapState([
      'filters'
    ]),
    isLoading () {
      return this.loading ?? this.isOrdersLoading
    },
    headers () {
      return [
        { text: '№', value: 'id', align: 'left', class: 'order-table-header-th' },
        { text: this.$t('table.column.delivery_time'), value: 'orderTime', align: 'left', class: 'order-table-header-th' },
        { text: this.$t('table.column.address'), value: 'address', align: 'left', class: 'order-table-header-th' },
        { text: this.$t('table.column.courier'), value: 'courier', align: 'left', class: 'order-table-header-th' },
        { text: this.$t('table.column.client'), value: 'client_name', align: 'left', class: 'order-table-header-th' },
        { text: this.$t('table.column.amount'), value: 'amount', align: 'center', class: 'order-table-header-th' }
      ]
    },
    filterHeight () {
      return document.querySelector('.order-filters')?.offsetHeight ?? 120
    },
    footerTableHeight () {
      return document.querySelector('.v-data-footer')?.offsetHeight ?? 59
    },
    currentHeight () {
      let height = this.height

      const filtersHeight = this.filterHeight
      const footerHeight = this.footerTableHeight

      if (this.withFilters) {
        if (height.indexOf('calc') >= 0) {
          height = height.substring(5, height.length - 1)
        }
        height = `calc(${height} - ${filtersHeight}px - ${footerHeight}px)`
      }
      return height
    },
    sortedOrders () {
      const orders = this.filteredOrders

      if (this.sort.value === null) {
        return orders
      }

      return orders.sort((a, b) => {
        let value = 0
        switch (this.sort.value) {
          case 'id':
            value = this.sortItems('id', a, b)
            break
          case 'orderTime':
            value = this.sortItems('datetime', a, b)
            break
          case 'address':
            value = this.sortItems('address', a, b)
            break
          case 'courier':
            value = this.sortItems('courier', a, b)
            break
          case 'client_name':
            value = this.sortItems('client_name', a, b)
            break
          case 'amount':
            value = this.sortItems('amount', a, b)
            break
        }
        return value
      })
    },
    filteredOrders () {
      if (!this.withFilters) {
        return this.orders
      }

      this.clearBadges()
      return this.orders.reduce((out, order) => {
        this.badges.types[order.type]++
        if (this.filters.type === 'all' || order.type === this.filters.type) {
          this.badges.statuses[order.status]++
          if (this.filters.statuses.includes(order.status)) {
            out.push(order)
          }
        }
        return out
      }, [])
    },
    clearBadgesStatuses () {
      return this.statusesForFilters.reduce((out, status) => {
        const ids = status.includes.reduce((ids, id) => {
          ids[id] = 0
          return ids
        }, {})
        out = { ...out, ...ids }
        return out
      }, {})
    }
  },
  methods: {
    ...mapActions([
      'loadOrders'
    ]),
    ...mapMutations([
      'updateFilters'
    ]),
    chooseOrder (order) {
      if (this.drag) return

      if (order.otherSeller(this.$store.state.user.id)) {
        this.otherSeller = {
          visible: true,
          order: order
        }
        return
      }
      this.$store.dispatch('showOrder', order.id)
    },
    clearBadges () {
      this.badges = {
        types: {
          delivery: 0,
          pickup: 0
        },
        statuses: { ...this.clearBadgesStatuses }
      }
    },
    selectDefaultStatuses () {
      this.filters.statuses = this.statusesForFilters.reduce((out, status) => {
        out = [...out, ...status.includes]
        return out
      }, [])
    },
    changeSort (col) {
      if (this.sort.value === col) {
        if (this.sort.type !== 'desc') {
          this.sort.type = 'desc'
        } else {
          this.sort.value = null
          this.sort.type = 'asc'
        }
      } else {
        this.sort.value = col
      }
    },
    mouseDownHandler (e) {
      this.pos = {
        left: this.$refs.tableBody.scrollLeft,
        top: this.$refs.tableBody.scrollTop,
        x: e.clientX,
        y: e.clientY
      }

      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('mouseup', this.mouseUpHandler)
    },
    mouseMoveHandler (e) {
      this.drag = true
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y

      this.$refs.tableBody.scrollTop = this.pos.top - dy
      this.$refs.tableBody.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler (e) {
      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
      setTimeout(() => {
        this.drag = false
      }, 30)
    },
    sortItems (type, a, b) {
      const fieldType = typeof a[type]

      let value = 0
      switch (fieldType) {
        case 'string':
          if (this.sort.type === 'asc') { value = a[type].localeCompare(b[type]) }
          if (this.sort.type === 'desc') { value = b[type].localeCompare(a[type]) }
          break
        case 'number':
          if (this.sort.type === 'asc') { value = a[type] - b[type] }
          if (this.sort.type === 'desc') { value = b[type] - a[type] }
          break
        case 'object':
          if (this.sort.type === 'asc') { value = a[type].name.localeCompare(b[type].name) }
          if (this.sort.type === 'desc') { value = b[type].name.localeCompare(a[type].name) }
          break
      }
      return value
    },
    changeFilters (filters) {
      this.updateFilters(filters)
    },
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
  mounted () {
    if (this.$refs.tableBody) {
      this.$refs.tableBody.addEventListener('mousedown', this.mouseDownHandler)
    }

    this.eventBus.$on('openOrder', (order) => {
      this.chooseOrder(order.id)
    })
  },
  created () {
    this.clearBadges()
    this.selectDefaultStatuses()
  }
}
</script>

<style lang="scss">
.order-table-rows {
  height: calc(100vh - 251px);
  overflow: auto;
}

.order-table-no-data {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .order-table-no-data-label {
    font-size: 20px;
    color: #000;
    margin-bottom: 2rem;
  }
}

.order-table-header-th {
  height: 30px !important;
  background-color: #0D47A1 !important;
  color: #fff !important;
  padding: 0 16px !important;
  & > i {
    color: #fff !important;
  }
}

.order-table-tr {
  height: 70px;
  & > td {
    border-top: .5px solid #e1e1e1;
    position: relative;
    margin: 0;
    vertical-align: middle;
    padding: 0 16px !important;
    height: 100% !important;
    border-bottom: none !important;
  }
}

.order-table-bottom-tr {
  height: 20px !important;
  border-bottom: 1px solid black;
  & > td.order-table-bottom-td {
    padding: 0 !important;
    height: 20px !important;
    border-bottom: 2px solid #1B2B61 !important;
    & > div {
      height: 20px !important;
      padding: 0 8px !important;
    }
  }
}

.order-comment-raw {
  width: calc(100% - 300px);
  & > div {
    width: 100%;
    display: flex;
    & > div {
      overflow: hidden;
    }
  }
}

.order-table-bottom-tr-status {
  font-size: 11px;
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.order-table-id {
  width: 160px;
  font-size: 16px !important;
}

.order-table-status {
  text-align: center;
  width: 28px;
  & > i {
    font-size: 28px !important;
  }
}

.order-table-date {
  padding: 0 24px !important;
  width: 150px;
}

.order-table-courier {
  width: 170px;
}

.order-table-client {
  width: 130px;
  font-size: 14px !important;
  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.order-table-amount {
  text-align: center;
  font-size: 20px !important;
  font-weight: 500;
  width: 140px;
}
</style>
