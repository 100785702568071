<template>
  <div class="order-table-footer">
    <div class="items">
      {{ $t('pagination.orders') }} {{ $t('pagination.since') }} {{ start }} - {{ end }} {{ $t('pagination.from') }} {{ total }}
    </div>
    <div class="pagination-arrows">
      <div class="pagination-arrow">
        <v-btn icon @click="prevPage" :disabled="isFirstPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="pagination-arrow">
        <v-btn icon @click="nextPage" :disabled="isLastPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTableFooter',
  props: {
    total: Number,
    currentPage: Number,
    perPage: Number
  },
  computed: {
    isFirstPage () {
      return this.currentPage === 1
    },
    isLastPage () {
      return Math.ceil(this.total / this.perPage) === this.currentPage
    },
    start () {
      if (this.isFirstPage) return 1

      return (this.currentPage - 1) * this.perPage + 1
    },
    end () {
      if (this.isLastPage) return this.total

      return (this.currentPage - Number(!this.isFirstPage)) * this.perPage
    }
  },
  methods: {
    prevPage () {
      if (this.isFirstPage) {
        return
      }

      this.$emit('change', this.currentPage - 1)
    },
    nextPage () {
      if (this.isLastPage) {
        return
      }

      this.$emit('change', this.currentPage + 1)
    }
  }
}
</script>

<style scoped lang="scss">
.order-table-footer {
  padding: 0 8px;
  display: grid;
  grid-template-columns: 160px 100px;
  font-size: 12px;
  gap: 25px;
  align-items: center;
  justify-content: end;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.items-on-page {
  display: flex;
  justify-content: end;
  align-items: center;
  .items-select {
    width: 60px;
    margin: 13px 0 13px 34px;
    .v-text-field {
      padding: 0;
      margin: 0;
    }
  }
}

.pagination-arrows {
  display: flex;
  .pagination-arrow {
    text-align: center;
    width: 50%;
    height: 100%;
    cursor: pointer;
    i {
      color: #7B829B;
      font-size: 26px !important;
    }
  }
}
</style>
