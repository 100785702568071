<template>
  <v-card>
    <v-card-title>
      {{ $t('service_pin_code') }}
    </v-card-title>
    <v-card-text>
      <div v-if="!cashRegisters.length">{{ $t('no_one_terminal') }}</div>
      <div v-else v-for="(pinCode, index) in cashRegisters" :key="index">
        <div class="d-flex justify-space-between align-center">
          <div>{{ pinCode.name }}</div>
          <div class="d-flex align-center">
            <div class="text-right ms-2">{{ pinCode.show ? pinCode.service_pin_code : '******' }}</div>
            <v-btn large icon @click="pinCode.show = !pinCode.show">
              <v-icon>{{ pinCode.show ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    cashRegisters: []
  }),
  methods: {
    ...mapMutations(['setLoading']),
    ...mapActions(['cashRegisterListQuery']),
    getCashRegisters () {
      this.setLoading({ value: true })
      this.cashRegisterListQuery().then(response => {
        this.cashRegisters = response.data.map(item => {
          item.show = false
          return item
        })
      }).finally(() => {
        this.setLoading({ value: false })
      })
    }
  },
  mounted () {
    this.getCashRegisters()
  }
}
</script>

<style scoped>

</style>
