<style lang="scss">
    body {
        background-color: #24276A;
    }

    .login-page {
        background: #24276A;
    }

    .login-page-wrap {
        position: relative;
        height: 100%;
        max-height: 25vh;
    }

    .logo-wrap {
        position: relative;
        top: 4rem;
        width: 100%;
        padding: 24px;
        text-align: center;

        img {
            max-width: 100%;
            margin-bottom: 0.6rem;
        }
    }

    .bottom-form {
        position: relative;
        padding-top: 8rem;
        width: 100%;
    }
</style>

<template>
    <v-container fluid fill-height grid-list-md :class="{'login-page': true}">
        <v-layout row wrap>
            <v-flex xs10 offset-xs1 md4 offset-md4>
                <div class="login-page-wrap">
                    <div class="logo-wrap">
                        <img src="/static/img/logo_white.svg" alt="">
                        <h4>{{ $t('main.name') }}</h4>
                    </div>
                    <div class="bottom-form">
                        <span class="title">{{ $t('authorization') }}</span>
                        <v-form ref="form" @keyup.native.enter="auth" lazy-validation>
                            <v-text-field
                              :label="$t('login')"
                              v-model="user.login"
                              :disabled="gotToken"
                              :error-messages="$t('form.valid_msg.enter_login')"
                              dark
                              required
                            ></v-text-field>

                            <v-text-field
                              v-if="!gotToken"
                              :label="$t('password')"
                              v-model="user.password"
                              :error-messages="$t('form.valid_msg.enter_pass')"
                              :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="() => (hidePassword = !hidePassword)"
                              :type="hidePassword ? 'password' : 'text'"
                              dark
                              required
                            ></v-text-field>

                            <v-btn block dark
                               v-if="!gotToken"
                               color="accent"
                               :disabled="!(user.login.length > 3 && user.password.length > 5)"
                               :loading="loading"
                               @click="auth">
                              {{ $t('log_in') }}
                            </v-btn>

                            <v-btn block dark
                               v-if="gotToken"
                               color="accent"
                               :loading="loading"
                               @click="logout">
                              {{ $t('logout') }}
                            </v-btn>

                            <p class="small" v-if="gotToken && loading">
                              {{ $t('auth.loading') }}
                            </p>

                            <div class="caption text--darken-4 ma-4 text-center">{{ $t('version.self') }} {{ settings.VERSION }}</div>
                            <div class="caption red--text" v-if="!settings.isProduction">{{ $t('version.for_dev') }}</div>
                        </v-form>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import settings from '@/config/settings'
import router from '@/router'
import WebServices from '@/webservices/main'

export default {
  data () {
    return {
      hidePassword: true,
      loading: false,
      settings: settings
    }
  },
  computed: {
    ...mapGetters([
      'gotToken',
      'getErrors',
      'getLocale',
      'user'
    ])
  },
  created () {
    this.$i18n.getLocaleMessage(this.getLocale || this.$i18n.locale)
  },
  methods: {
    ...mapActions([
      'login',
      'loginByToken',
      'logout',
      'setIntervals',
      'loadOrders'
    ]),
    auth () {
      this.loading = true

      this.login().then(response => {
        this.loadOrders()
        this.setIntervals()
        this.checkSelectRider(response)
        this.connectSocketAndWorker(response.websocket_token, response.id)
        router.push({ name: 'orders' })
      }).finally(() => {
        this.loading = false
      })
    },
    connectSocketAndWorker (token, userId) {
      (new WebServices()).connect(userId, token)
    },
    checkSelectRider (response) {
      if (!response.selectRider) {
        this.$store.dispatch('showSnackbar', {
          message: 'У вас не хватает прав для изменения курьера в заказе',
          type: 'warning'
        })
      }
    }
  },
  mounted () {
    this.$store.state.user.logged = false

    if (this.gotToken) {
      this.loading = true

      this.loginByToken().then(response => {
        this.loadOrders()
        this.setIntervals()
        this.checkSelectRider(response)
        this.connectSocketAndWorker(response.websocket_token, response.id)
        router.push({ name: 'orders' })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
