<template>
  <div class="order-table-row unselectable" @click="$emit('click', order)">
    <div class="order-table-col order-status-col" :class="[order.statusColor, {'with-notice': !isAccepted}]">
      <div class="order-number" :style="{color: iconColor}">
        <div>
          {{ orderNumber }}
        </div>
        <div class="status-icon">
          <component :is="iconComponent" :style="{fill: iconColor}"/>
        </div>
      </div>
      <div class="status-label" :class="`${statusRowColor} lighten-3`" :style="{color: iconColor}">
        <div v-if="!isAccepted">{{ $t('table.not_confirmed') }}</div>
        <div v-else>{{ statusName }}</div>
      </div>
    </div>

    <div class="order-table-double-row">
      <div class="order-table-double-row_1" :class="{'red': notSendingToIiko, 'lighten-4': notSendingToIiko}">
        <div class="order-table-col order-datetime-col">
          <div :class="{title: !order.soon}">
            {{ order.orderTime }}
          </div>

          <div>
            {{ order.date }}
            <span v-if="order.soon"><span class="px-1">|</span>{{ order.time }}</span>
          </div>
        </div>

        <div class="order-table-col order-address-col" v-html="order.address"></div>

        <div class="order-table-col order-courier-col">
            <span v-if="order.courier_service_id && order.courier.icon">
              <v-img max-height="16" max-width="16" :src="order.courier.icon"></v-img>
            </span>
            {{ order.courier.name }}
        </div>

        <div class="order-table-col order-client-col">
          <div>
            {{ order.client_name }}
          </div>
          <div>
            <span class="font-weight-bold">{{ order.phone_client }}</span>
          </div>
        </div>
      </div>

      <div class="order-table-double-row_2">
        <div class="order-table-col order-comment-col">
          <div class="order-warning-iiko red darken-3" v-if="notSendingToIiko">
            <warning-icon class="mr-2 icon-blink"/> <span>{{ $t('table.not_transferred') }}</span>
          </div>
          <div class="order-comment" v-else-if="hasComment" :class="{'red lighten-5': hasComment}">
            {{ $t('comment') }}: <div class="font-weight-bold ml-1">{{ order.additional_info }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-table-col order-amount-col" :class="{'red': notSendingToIiko, 'lighten-4': notSendingToIiko}">
      <div class="order-amount">
        {{ amount }}
      </div>

      <div class="order-paid-status" :class="{'light-green lighten-1': order.paid, 'red lighten-5': hasComment}">
        <div v-if="notSendingToIiko" class="order-comment-not-send-iiko red darken-3"></div>
        <span v-else-if="order.paid">{{ $t('paid') }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import settings from '@/config/settings'

export default {
  name: 'OrderTableRow',
  props: {
    order: Object
  },
  computed: {
    statusRowColor () {
      return this.order.statusRowColor
    },
    orderNumber () {
      const id = this.order.id.toString()
      return id.substring(0, id.length - 3) + ' ' + id.substring(id.length - 3)
    },
    iconComponent () {
      return this.order.statusIcon.component
    },
    iconColor () {
      return this.order.statusIcon.color
    },
    isAccepted () {
      return this.order.accepted
    },
    statusName () {
      return this.order.statusName
    },
    hasComment () {
      return this.order.additional_info.length > 0
    },
    amount () {
      return Number(this.order.amount).toFixed(2)
    },
    notSendingToIiko () {
      return settings.ORDER_STATUSES_FOR_IIKO.includes(this.order.status) && !this.order.iikoNumber
    }
  }
}
</script>

<style scoped lang="scss">
$row_height: 80px;
$row_1_height: 75%;
$row_2_height: 25%;

$row_bg_color: #fff;

.background-warning {
  background-color: #EBB4B4;
}

.order-comment-not-send-iiko {
  height: 100%;
  width: 100%;
}

.order-table-row {
  min-height: $row_height;
  display: grid;
  grid-template-columns: 160px 1fr 110px;
  border-bottom: 1px solid #1B2B61;
  cursor: pointer;
  background-color: $row_bg_color;
  color: #000;

  &:hover {
    background-color: #eeeeee;
  }
}

.order-table-double-row {
  height: 100%;
  display: grid;
  grid-template-rows: $row_1_height $row_2_height;

  .order-table-double-row_1 {
    display: grid;
    grid-template-columns: 150px 1fr 150px 150px;
  }
  .order-table-double-row_2 {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.order-table-col {
  font-size: 13px;
}

.order-comment {
  height: 100%;
  display: flex;
  font-size: 13px;
  padding: 0 16px;
  align-items: center;
}

.order-warning-iiko {
  height: 100%;
  font-size: 13px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}

.order-status-col {
  display: grid;
  grid-template-rows: $row_1_height $row_2_height;

  .order-number {
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 0 16px;
    font-size: 16px;
    div {
      display: flex;
      align-items: center;
    }
    .status-icon {
      text-align: right;
      justify-content: end;
    }
  }
  .status-label {
    font-size: 11px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.order-datetime-col {
  font-size: 14px;
  padding: 0 16px;
  display: grid;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.order-address-col {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.order-courier-col {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
}

.order-client-col {
  display: grid;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding: 0 16px;
}

.order-amount-col {
  display: grid;
  grid-template-rows: $row_1_height $row_2_height;

  .order-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }

  .order-paid-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
}

.icon-blink {
  -webkit-animation: blink 1.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: blink 1.5s;
  -moz-animation-iteration-count: infinite;
  -o-animation: blink 1.5s;
  -o-animation-iteration-count: infinite;
}
</style>
